import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Banner from 'components/Banner';
import { BannerMode } from 'components/Banner/models.d';
import Faq from 'components/Faq';
import PageDescription from 'components/PageDescription';
import SecondaryNavigation from 'components/SecondaryNavigation';
import Teaser from 'components/Teaser';
import createBreadcrumbs from 'utils/createBreadcrumbs';

import { FaqPageProps } from './models';

import './FaqPage.scss';

const FaqPage: FC<FaqPageProps> = ({
  data: {
    faqPage: { seo, langProps, banner, faq, pageDescription, teaser },
    allSitePage: { nodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
    pagePathname,
  },
  location: { pathname },
}) => {
  const breadcrumbs = createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname);

  const secondaryNavigationAnchors = faq.map(
    (faqContainerItem) => faqContainerItem.secondaryNavigationAnchor
  );

  return (
    <Layout {...{ seo, langProps, breadcrumbs, pagePathname }}>
      <Banner {...banner[0]} mode={BannerMode.article} />
      {secondaryNavigationAnchors ? (
        <SecondaryNavigation anchors={secondaryNavigationAnchors} lang={langProps.lang} />
      ) : null}
      {pageDescription ? <PageDescription {...pageDescription} /> : null}
      <div className="faqs">
        {faq.map((faqContainerItem, i, arr) => (
          <div className="faq-container" key={faqContainerItem.secondaryNavigationAnchor}>
            <Faq {...faqContainerItem} />

            {arr.length - 1 !== i ? (
              <hr className="horizontal-line horizontal-line--with-margin-left-right" />
            ) : null}
          </div>
        ))}
      </div>
      {teaser ? <Teaser {...teaser} /> : null}
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    faqPage(link: { eq: $link }) {
      seo {
        ...SEOStructureFragment
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      banner {
        ...BannerFragment
      }
      pageDescription {
        description
        backgroundColor
      }
      faq {
        ...FaqFragment
      }
      teaser {
        ...TeaserFragment
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default FaqPage;
